import React, { useEffect, useState } from 'react';
import axios from 'axios';
import NavbarAuth from '../Components/Navbar_Authenticated';
import { useAuth } from "../Components/AuthContext.js";
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './PageCSS/Dashboard.css';

export default function Dashboard() {
    const [projects, setProjects] = useState([]);
    const { user, isAuthenticated } = useAuth();

    useEffect(() => {
        let isMounted = true;

        if (isAuthenticated && user) {
            console.log('User: ', user);
            fetchProjects(user.user_id, isMounted);
        } else {
            console.log('User is not being fetched');
        }

        return () => {
            console.log('Dashboard component unmounted');
            isMounted = false;
        };

    }, [isAuthenticated, user]);

    const fetchProjects = async (userId, isMounted) => {
        try {
            console.log('Fetching project for user ID: ', userId);
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/projects/${userId}`);
            console.log('Projects fetched:', response.data);
            if (isMounted) {
                setProjects(response.data);
            }
        } catch (e) {
            if (e.response) {
                console.error('Error fetching projects: ', e);
            } else if (e.request) {
                console.error('Network error:', e.request);
            } else {
                console.error('Error:', e.message);
            }
        }
    };
    
    return (
        <div className="App">
            <header className="siteHeader text-white text-center">
                <NavbarAuth />
            </header>
            <main className="container mt-5">
                <h1 className="text-center mb-4">Project Dashboard</h1>
                <div className="d-flex justify-content-between mb-4">
                    <Link to="/newProject" className="btn btn-primary">New Project</Link>
                </div>
                <div className="row">
                    {projects.length === 0 ? (
                        <p className="text-center">No projects found</p>
                    ) : (
                        projects.map((project) => (
                            <div key={project._id} className="col-md-3">
                                <div className="card project-card">
                                    <div className="card-body">
                                        <h5 className="card-title">{project.project_name}</h5>
                                        <Link to={`/projects/${project._id}`} className="btn btn-outline-primary">View Project</Link>
                                    </div>
                                </div>
                            </div>
                        ))
                    )}
                </div>
            </main>
        </div>
    );
};