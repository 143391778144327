import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import './PageCSS/ResetPassword.css';
import Navbar from '../Components/Navbar.js';

export default function ResetPassword() {
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const [token, setToken] = useState('');

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        const token = query.get('token');
        if (token) {
            setToken(token);
        } else {
            setMessage('Invalid or missing token');
        }
    }, [location.search]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log('Token:', token);
        console.log('New Password:', password);
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/reset-password`, { token, password });
            setMessage('Password reset successful');
            navigate('/login');
        } catch (e) {
            setMessage('Error resetting password');
        }
    };

    return (
        <div className = "App">
            <header className = "siteHeader text-white text-center">
                <Navbar />
            </header>
            <div className="container mt-5">
                <div className="row justify-content-center">
                    <div className="col-md-6">
                        <div className="card">
                            <div className="card-body">
                                <h1 className="card-title text-center">Reset Password</h1>
                                <form onSubmit={handleSubmit}>
                                    <div className="form-group">
                                        <label>New Password</label>
                                        <input
                                            type="password"
                                            className="form-control"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            placeholder="Enter new password"
                                            required
                                        />
                                    </div>
                                    <button type="submit" className="btn btn-primary btn-block">
                                        Reset Password
                                    </button>
                                </form>
                                {message && <p className="text-center mt-3">{message}</p>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}