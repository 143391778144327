import React, { useState } from 'react';
import NavbarAuth from './Navbar_Authenticated';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import "./NewStakeholderRegister.css";

const NewStakeholderRegister = () => {
    const { projectId } = useParams();
    const navigate = useNavigate();
    const [stakeholders, setStakeholders] = useState([
        {name: '', role: '', email: ''}
    ]);

    const handleChange = (index, e) => {
        const {name, value} = e.target;
        const newStakeholders = [...stakeholders];
        newStakeholders[index][name] = value;
        setStakeholders(newStakeholders);
    };

    const handleAddRow = () => {
        setStakeholders([...stakeholders, {name: '', role: '', email: '' }]);
    };

    const handleDeleteRow = (index) => {
        const newStakeholders = stakeholders.filter((_, i) => i !== index);
        setStakeholders(newStakeholders);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/projects/${projectId}/newStakeholderRegister`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ stakeholders, projectId })
            });
            if (response.ok) {
                console.log('Stakeholders updated successfully');
                navigate(`/projects/${projectId}`);
            } else {
                console.error('Failed to update stakeholders');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <div>
            <NavbarAuth />
            <h1>Create New Stakeholder Register</h1>
            <form onSubmit = {handleSubmit}>
                <table>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Role</th>
                            <th>Email</th>
                            <th>Delete</th>
                        </tr>
                    </thead>
                    <tbody>
                        {stakeholders.map((stakeholder, index) => (
                            <tr key = {index}>
                                <td>
                                    <input
                                        type = "text"
                                        name = "name"
                                        value = {stakeholder.name}
                                        onChange = {(e) => handleChange(index, e)}
                                        required
                                    />
                                </td>
                                <td>
                                <input
                                        type = "text"
                                        name = "role"
                                        value = {stakeholder.role}
                                        onChange = {(e) => handleChange(index, e)}
                                        required
                                    />
                                </td>
                                <td>
                                    <input
                                        type = "text"
                                        name = "email"
                                        value = {stakeholder.email}
                                        onChange = {(e) => handleChange(index, e)}
                                        required
                                    />
                                </td>
                                <td>
                                    <button type = "button" onClick={() => handleDeleteRow(index)} className = "delete-button">x</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <button type = "button" onClick = {handleAddRow}>Add Row</button>
                <button type = "submit">Submit</button>
            </form>
        </div>
    )
};

export default NewStakeholderRegister;