import React, { useState } from 'react';
import "./NewArtifactDropdown.css";
import { Link } from "react-router-dom";
import { useParams } from 'react-router-dom';


const NewArtifactDropDown = () => {
    const [isOpen, setIsOpen] = useState(false);
    const { projectId } = useParams(); // Get projectId from URL

    const toggleDown = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div>
            <button onClick = {toggleDown} className = "dropdown">
                CreateArtifact
            </button>
            <div className = "drop-down-menu">
                {isOpen && (
                    <ul>
                        <li className = "dropdown-item"><Link to = {`/projects/${projectId}/newStakeholderRegister`}>Stakeholder Register</Link></li>
                        <li className = "dropdown-item"><Link to = {`/projects/${projectId}/newRAIDLog`}>RAID Log</Link></li>
                    </ul>
                )}
            </div>
        </div>
    );
};

export default NewArtifactDropDown;