import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import NavbarAuth from './Navbar_Authenticated';
import { Link } from 'react-router-dom';
import "./ComponentStyling/RAIDLog.css";

const RAIDLog = () => {
    const { projectId } = useParams();
    const [raidlog, setRAID] = useState([]);
    const [originalRaidLog, setOriginalRAID] = useState([]);
    const [isEditing, setIsEditing] = useState(null);
    const [columns, setColumns] = useState(['Action', 'Owner', 'Status', 'Priority']);
    const [showDropdown, setShowDropdown] = useState(false);
    const dropdownRef = useRef(null);

    useEffect(() => {
        const fetchRAID = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/projects/${projectId}/raidlog`);
                const sortedData = response.data.sort((a, b) => a.id - b.id);
                setRAID(sortedData);
                setOriginalRAID(sortedData);
            } catch (e) {
                console.error('Error fetching RAID Log:', e);
            }
        };

        fetchRAID();
    }, [projectId]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowDropdown(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleEditChange = (index, e) => {
        const { name, value } = e.target;
        const updatedRAIDLog = [...raidlog];
        updatedRAIDLog[index] = { ...updatedRAIDLog[index], [name]: value };
        setRAID(updatedRAIDLog);
    };

    const handleAddRow = () => {
        const newId = raidlog.length > 0 ? Math.max(...raidlog.map(item => item.id)) + 1 : 1;
        const newRow = columns.reduce((acc, column) => {
            acc[column.toLowerCase()] = '';
            return acc;
        }, { id: newId });
        setRAID([...raidlog, newRow]);
    };

    const handleSave = async (index) => {
        const raidlogitem = raidlog[index];
        const originalItem = originalRaidLog[index];
        const isNewItem = !raidlogitem._id;

        const itemToSave = {
            ...raidlogitem,
            projectId: projectId.toString()
        };

        const updatedRAIDLog = raidlog.map((item, idx) => (idx === index ? itemToSave : item));
        setRAID(updatedRAIDLog);

        try {
            if (isNewItem) {
                const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/raidlog/newItem`, itemToSave);
                if (response.status === 201) {
                    await fetchRAID();
                    setIsEditing(null);
                }
            } else {
                const hasChanges = Object.keys(raidlogitem).some(
                    key => raidlogitem[key] !== originalItem[key]
                );

                if (hasChanges) {
                    const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/raidlog/${raidlogitem._id}`, itemToSave);
                    if (response.status === 200) {
                        await fetchRAID();
                        setIsEditing(null);
                    }
                } else {
                    setIsEditing(null);
                }
            }
        } catch (error) {
            console.error('Error saving RAID log item:', error);
            setRAID(raidlog);
        }
    };

    const fetchRAID = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/projects/${projectId}/raidlog`);
            const sortedData = response.data.sort((a, b) => a.id - b.id);
            setRAID(sortedData);
            setOriginalRAID(sortedData);
        } catch (e) {
            console.error('Error fetching RAID Log:', e);
        }
    };

    const handleDelete = async (index) => {
        const raidlogitemToDelete = raidlog[index];
    
        if (!raidlogitemToDelete._id) {
            const updatedRAIDLog = raidlog.filter((_, i) => i !== index).map((item, idx) => ({ ...item, id: idx + 1 }));
            setRAID(updatedRAIDLog);
            setOriginalRAID(updatedRAIDLog);
            return;
        }
    
        try {
            const response = await axios.delete(`${process.env.REACT_APP_API_URL}/api/raidlog/${raidlogitemToDelete._id}`);
            if (response.status === 200) {
                // Reassign IDs for the remaining items
                const updatedRAIDLog = raidlog
                    .filter((_, i) => i !== index)
                    .map((item, idx) => ({ ...item, id: idx + 1 }));
    
                setRAID(updatedRAIDLog);
                setOriginalRAID(updatedRAIDLog);
    
                // Log the updated RAID log to debug
                console.log('Updated RAID Log:', updatedRAIDLog);
    
                // Save the updated RAID log to the database
                for (const item of updatedRAIDLog) {
                    console.log('Updating item with id:', item.id);
                    try {
                        const putResponse = await axios.put(`${process.env.REACT_APP_API_URL}/api/raidlog/${item._id}`, { id: item.id });
                        console.log('PUT Response:', putResponse);
                    } catch (putError) {
                        console.error('Error updating item:', item, putError);
                    }
                }
    
                // Fetch the updated RAID log from the database
                await fetchRAID();
            }
        } catch (error) {
            console.error('Error deleting RAID Log item:', error);
        }
    };

    const handleColumnChange = (column) => {
        setColumns(prevColumns =>
            prevColumns.includes(column)
                ? prevColumns.filter(col => col !== column)
                : [...prevColumns, column]
        );
    };

    return (
        <div className="App">
            <NavbarAuth />
            <div className="container-fluid">
                <div className="row">
                    <nav className="col-md-3 col-lg-2 d-md-block bg-light sidebar">
                        <div className="position-sticky pt-3">
                            <ul className="nav flex-column">
                                <li className="nav-item">
                                    <Link className="nav-link active" to={`/projects/${projectId}`}>
                                        Project Overview
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to={`/projects/${projectId}/stakeholders`}>
                                        Stakeholder Register
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to={`/projects/${projectId}/raidlog`}>
                                        RAID Log
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </nav>
                    <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
                        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                            <h1 className="h2">RAID Log</h1>
                            <div className="dropdown" ref={dropdownRef}>
                                <button className="btn btn-primary" onClick={() => setShowDropdown(!showDropdown)}>Columns</button>
                                {showDropdown && (
                                    <div className="dropdown-menu show">
                                        {['Action', 'Owner', 'Status', 'Priority', 'Due Date'].map((col, index) => (
                                            <div key={index} className="dropdown-item">
                                                <input
                                                    type="checkbox"
                                                    id={col}
                                                    checked={columns.includes(col)}
                                                    onChange={() => handleColumnChange(col)}
                                                />
                                                <label htmlFor={col}>{col}</label>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>
                        {raidlog.length === 0 ? (
                            <p>No RAID Log Found</p>
                        ) : (
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        {columns.map((col, index) => (
                                            <th key={index}>{col}</th>
                                        ))}
                                        <th>Delete</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {raidlog.map((raidlogitem, index) => (
                                        <tr key={index}>
                                            <td>{raidlogitem.id}</td>
                                            {columns.map((col, colIndex) => (
                                                <td key={colIndex}>
                                                    {col === 'Status' ? (
                                                        <select
                                                            name="status"
                                                            value={raidlogitem.status || ''}
                                                            onChange={(e) => handleEditChange(index, e)}
                                                            onBlur={() => handleSave(index)}
                                                        >
                                                            <option value="Not Started">Not Started</option>
                                                            <option value="In Progress">In Progress</option>
                                                            <option value="On Hold">On Hold</option>
                                                            <option value="Complete">Complete</option>
                                                        </select>
                                                    ) : col === 'Priority' ? (
                                                        <select
                                                            name="priority"
                                                            value={raidlogitem.priority || ''}
                                                            onChange={(e) => handleEditChange(index, e)}
                                                            onBlur={() => handleSave(index)}
                                                        >
                                                            <option value="Low">Low</option>
                                                            <option value="Medium">Medium</option>
                                                            <option value="High">High</option>
                                                        </select>
                                                    ) : (
                                                        <input
                                                            type="text"
                                                            name={col.toLowerCase()}
                                                            value={raidlogitem[col.toLowerCase()] || ''}
                                                            onChange={(e) => handleEditChange(index, e)}
                                                            onBlur={() => handleSave(index)}
                                                        />
                                                    )}
                                                </td>
                                            ))}
                                            <td>
                                                <button className="btn btn-danger" onClick={() => handleDelete(index)}>Delete</button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        )}
                        <button className="btn btn-success" type="button" onClick={handleAddRow}>Add Row</button>
                    </main>
                </div>
            </div>
        </div>
    );
};

export default RAIDLog;