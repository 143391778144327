import React, { useState } from 'react';
import NavbarAuth from './Navbar_Authenticated';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import "./NewRAIDLog.css";

const NewRAIDLog = () => {
    const { projectId } = useParams();
    const navigate = useNavigate();
    const [raidlog, setRAID] = useState([
        {task: '', owner: '', status: ''}
    ]);

    const handleChange = (index, e) => {
        const {name, value} = e.target;
        const newRAIDLog = [...raidlog];
        newRAIDLog[index][name] = value;
        setRAID(newRAIDLog);
    };

    const handleAddRow = () => {
        setRAID([...raidlog, {task: '', owner: '', status: ''}]);
    };

    const handleDeleteRow = (index) => {
        const newRAIDLog = raidlog.filter((_, i) => i !== index);
        setRAID(newRAIDLog);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/projects/${projectId}/newRaidLog`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ raidlog, projectId })
            });
            if (response.ok) {
                console.log('RAID Log updated successfully');
                navigate(`/projects/${projectId}`);
            } else {
                console.error('Failed to update RAIDLog');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <div>
            <NavbarAuth />
            <h1>Create New RAID Log</h1>
            <form onSubmit = {handleSubmit}>
                <table>
                    <thead>
                        <tr>
                            <th>Task</th>
                            <th>Owner</th>
                            <th>Status</th>
                            <th>Delete</th>
                        </tr>
                    </thead>
                    <tbody>
                        {raidlog.map((raidlogitem, index) => (
                            <tr key = {index}>
                                <td>
                                    <input
                                        type = "text"
                                        name = "task"
                                        value = {raidlogitem.task}
                                        onChange = {(e) => handleChange(index, e)}
                                        required
                                    />
                                </td>
                                <td>
                                <input
                                        type = "text"
                                        name = "owner"
                                        value = {raidlogitem.owner}
                                        onChange = {(e) => handleChange(index, e)}
                                        required
                                    />
                                </td>
                                <td>
                                    <input
                                        type = "text"
                                        name = "status"
                                        value = {raidlogitem.status}
                                        onChange = {(e) => handleChange(index, e)}
                                        required
                                    />
                                </td>
                                <td>
                                    <button type = "button" onClick={() => handleDeleteRow(index)} className = "delete-button">x</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <button className = "addRow" type = "button" onClick = {handleAddRow}>Add Row</button>
                <button className = "submit" type = "submit">Submit</button>
            </form>
        </div>
    )
};

export default NewRAIDLog;