import React, { createContext, useContext, useState, useMemo } from 'react';

const AuthContext = createContext();

export function AuthProvider({ children }) {
    const [token, setToken] = useState(localStorage.getItem('token'));
    const [tokenExpiration, setTokenExpiration] = useState(localStorage.getItem('tokenExpiration'));
    const [user, setUser] = useState(() => {
        const storedUser = localStorage.getItem('user_id');
        return storedUser ? JSON.parse(storedUser) : null; // Initialize user from localStorage
    });

    const isAuthenticated = useMemo(() => {
        if (token && tokenExpiration) {
            const currentTime = new Date().getTime();
            if (currentTime < tokenExpiration) {
                console.log('Token is valid, user is authenticated');
                return true;
            } else {
                localStorage.removeItem('token');
                localStorage.removeItem('tokenExpiration');
                localStorage.removeItem('user_id'); // Correct key
                console.log('Token expired, user is logged out');
                return false;
            }
        }
        return false;
    }, [token, tokenExpiration]);

    const login = (newToken, expirationTime, userData) => {
        setToken(newToken);
        setTokenExpiration(expirationTime);
        setUser(userData);
        localStorage.setItem('token', newToken);
        localStorage.setItem('tokenExpiration', expirationTime);
        localStorage.setItem('user_id', JSON.stringify(userData.user_id)); // Store user ID correctly
    };

    const logout = () => {
        setToken(null);
        setTokenExpiration(null);
        localStorage.removeItem('token');
        localStorage.removeItem('tokenExpiration');
        localStorage.removeItem('user_id'); // Correct key
        console.log('User successfully logged out');
    };

    return (
        <AuthContext.Provider value={{ isAuthenticated, user, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
}

export function useAuth() {
    const context = useContext(AuthContext);
    
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
}