import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Navbar from './Navbar';

export default function Register() {
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/register`, { firstname, lastname, email, password });
            console.log(res)
            setMessage('Registration successful');
            navigate('/login');
        } catch (e) {
            console.error('Error:', e.response ? e.response.data : e.message);
            setMessage(e.response ? e.response.data.message : 'Server error');
        };
    };

    return (
        <div>
            <Navbar />
            <h1>Register</h1>
            <form onSubmit = { handleSubmit }>
                <div>
                    <label>First Name</label>
                    <input type = 'text' value = {firstname} onChange = {(e) => setFirstname(e.target.value)} />
                </div>
                <div>
                    <label>Last Name</label>
                    <input type = 'text' value = { lastname } onChange = {(e) => setLastname(e.target.value)} />
                </div>
                <div>
                    <label>Email</label>
                    <input type = 'email' value = { email } onChange = {(e) => setEmail(e.target.value)} />
                </div>
                <div>
                    <label>Set Password</label>
                    <input type = 'password' value = { password } onChange = {(e) => setPassword(e.target.value)} />
                </div>
                <button type = 'submit'>Register</button>
            </form>
            {message && <p>{message}</p>}
        </div>
    );
};