import React from "react";
import "./Footer.css";

const Footer = () => {
    const year = new Date().getFullYear();
    return (
        <footer>
            <p>{year} PMHub.io &copy;</p>
        </footer>
    );
};

export default Footer;