import React, { useState } from 'react';
import NavbarAuth from '../Components/Navbar_Authenticated';
import axios from 'axios';
import { useAuth } from "../Components/AuthContext.js";
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './PageCSS/NewProject.css';

export default function NewProject() {
    const { user } = useAuth();
    const [project_name, setProjectName] = useState('');
    const [description, setDescription] = useState('');
    const [created_date, setCreatedDate] = useState('');
    const [projectState] = useState('Active');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        const newProject = {
            project_name: project_name,
            description,
            created_date: created_date,
            projectState,
            user_id: user.user_id
        };

        console.log(newProject);

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/projects/newProject`, newProject);
            console.log('Project created', response.data);
            navigate('/dashboard');
        } catch (error) {
            console.error('Error creating project: ', error);
        }
    }

    return (
        <div className="App">
            <NavbarAuth />
            <div className="container mt-5">
                <h1 className="text-center mb-4">Create New Project</h1>
                <form onSubmit={handleSubmit} className="form-group">
                    <div className="mb-3">
                        <label htmlFor="projectName" className="form-label">Project Name</label>
                        <input
                            type="text"
                            id="projectName"
                            className="form-control"
                            placeholder="Project Name"
                            value={project_name}
                            onChange={(e) => setProjectName(e.target.value)}
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="description" className="form-label">Description</label>
                        <textarea
                            id="description"
                            className="form-control"
                            placeholder="Description"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="createdDate" className="form-label">Created Date</label>
                        <input
                            type="date"
                            id="createdDate"
                            className="form-control"
                            value={created_date}
                            onChange={(e) => setCreatedDate(e.target.value)}
                            required
                        />
                    </div>
                    <button type="submit" className="btn btn-primary">Create Project</button>
                </form>
            </div>
        </div>
    );
}