import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import NavbarAuth from './Navbar_Authenticated';
import './ComponentStyling/StakeholderRegister.css';
import { Link } from 'react-router-dom';

const StakeholderRegister = () => {
    const { projectId } = useParams();
    const [stakeholders, setStakeholders] = useState([]);
    const [originalStakeholders, setOriginalStakeholders] = useState([]);
    const [isEditing, setIsEditing] = useState(null);

    useEffect(() => {
        const fetchStakeholders = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/projects/${projectId}/stakeholders`);
                setStakeholders(response.data);
                setOriginalStakeholders(response.data);
            } catch (e) {
                console.error('Error fetching stakeholders:', e);
            }
        };

        fetchStakeholders();
    }, [projectId]);

    const handleEditChange = (index, e) => {
        const { name, value } = e.target;
        const updatedStakeholder = [...stakeholders];
        updatedStakeholder[index][name] = value;
        setStakeholders(updatedStakeholder);
    };

    const handleSave = async (index) => {
        const stakeholder = stakeholders[index];
        const originalStakeholder = originalStakeholders[index];

        const hasChanges =
            stakeholder.name !== originalStakeholder.name ||
            stakeholder.role !== originalStakeholder.role ||
            stakeholder.email !== originalStakeholder.email;

        if (!hasChanges) {
            console.log('No changes detected');
            setIsEditing(null);
            return;
        }

        try {
            const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/stakeholders/${stakeholder._id}`, stakeholder);
            if (response.status === 200) {
                setIsEditing(null);
            }
        } catch (error) {
            console.error('Error updating stakeholders:', error);
        }
    };

    const handleDelete = async (index) => {
        const stakeholderToDelete = stakeholders[index];
        try {
            const response = await axios.delete(`${process.env.REACT_APP_API_URL}/api/stakeholders/${stakeholderToDelete._id}`);
            if (response.status === 200) {
                setStakeholders(stakeholders.filter((_, i) => i !== index));
                setOriginalStakeholders(originalStakeholders.filter((_, i) => i !== index));
            }
        } catch (error) {
            console.error('Error deleting stakeholders:', error);
        }
    };

    return (
        <div className="App">
            <NavbarAuth />
            <div className="container-fluid">
                <div className="row">
                    <nav className="col-md-3 col-lg-2 d-md-block bg-light sidebar">
                        <div className="position-sticky pt-3">
                            <ul className="nav flex-column">
                                <li className="nav-item">
                                    <Link className="nav-link active" to={`/projects/${projectId}`}>
                                        Project Overview
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to={`/projects/${projectId}/stakeholders`}>
                                        Stakeholder Register
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to={`/projects/${projectId}/raidlog`}>
                                        RAID Log
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </nav>
                    <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
                        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                            <h1 className="h2">Stakeholder Register</h1>
                        </div>
                        {stakeholders.length === 0 ? (
                            <p>No Stakeholders Found</p>
                        ) : (
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Role</th>
                                        <th>Email</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {stakeholders.map((stakeholder, index) => (
                                        <tr key={index}>
                                            <td>
                                                {isEditing === index ? (
                                                    <input
                                                        type="text"
                                                        name="name"
                                                        value={stakeholder.name}
                                                        onChange={(e) => handleEditChange(index, e)}
                                                    />
                                                ) : (
                                                    stakeholder.name
                                                )}
                                            </td>
                                            <td>
                                                {isEditing === index ? (
                                                    <input
                                                        type="text"
                                                        name="role"
                                                        value={stakeholder.role}
                                                        onChange={(e) => handleEditChange(index, e)}
                                                    />
                                                ) : (
                                                    stakeholder.role
                                                )}
                                            </td>
                                            <td>
                                                {isEditing === index ? (
                                                    <input
                                                        type="text"
                                                        name="email"
                                                        value={stakeholder.email}
                                                        onChange={(e) => handleEditChange(index, e)}
                                                    />
                                                ) : (
                                                    stakeholder.email
                                                )}
                                            </td>
                                            <td>
                                                {isEditing === index ? (
                                                    <button className="btn btn-primary" onClick={() => handleSave(index)}>Save</button>
                                                ) : (
                                                    <button className="btn btn-secondary" onClick={() => setIsEditing(index)}>Edit</button>
                                                )}
                                                <button className="btn btn-danger" onClick={() => handleDelete(index)}>Delete</button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        )}
                    </main>
                </div>
            </div>
        </div>
    );
};

export default StakeholderRegister;