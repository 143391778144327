import { useState } from 'react';
import NavbarAuth from './Navbar_Authenticated';
import { DndContext, closestCorners } from "@dnd-kit/core";
import Column from "./Columns/Column.jsx";
import { arrayMove } from '@dnd-kit/sortable';
import Input from "./Input/Input";

export default function TriColumnToDo() {
    const [tasks, setTasks ] = useState([
        { id: 1, title: "Example 1"},
        { id: 2, title: "Example 2"},
        { id: 3, title: "Example 3"}
    ]);

    const addTask = title => {
        setTasks(tasks => [...tasks, {id: tasks.length + 1, title}]);
    };

    const getTaskPosition = id => tasks.findIndex(task => task.id === id)

    const handleDragEnd = event => {
        const {active, over} = event

        if (active.id === over.id) return

        setTasks(tasks => {
            const originalPos = getTaskPosition(active.id);
            const newPos = getTaskPosition(over.id);

            return arrayMove(tasks, originalPos, newPos);
        });
    };

    return (
        <div>
            <NavbarAuth />
            <h1>To Do List</h1>
            <DndContext onDragEnd={handleDragEnd} collisionDetection={ closestCorners }>
                <Input onSubmit = {addTask} />
                <Column tasks={ tasks }/>
            </DndContext>
            <DndContext onDragEnd={handleDragEnd} collisionDetection={ closestCorners }>
                <Column tasks = { tasks } />
            </DndContext>
        </div>
    );
};