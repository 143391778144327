import React, { useEffect, useState } from 'react';
import { useAuth } from "../Components/AuthContext.js"; 
import NavbarAuth from '../Components/Navbar_Authenticated';
import axios from 'axios';

const AccountPage = () => {
    const { user, isAuthenticated } = useAuth();
    const [userProfile, setUserProfile] = useState(null);
    
    useEffect(() => {
        let isMounted = true;

        const fetchUser = async (email, isMounted) => {
            try {
                console.log('Fetching user information for ID:', user.email);
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/user/${user.email}`);
                console.log('User information fetched:', response.data);
                if (isMounted) {
                    setUserProfile(response.data);
                }
            } catch (e) {
                console.error('Error fetching user data:', e);
            }
        };

        if (isAuthenticated && user) {
            console.log('User from context:', user);
            const userId = user.user_id; // Access the user ID correctly

            fetchUser(userId, isMounted);
        } else {
            console.log('User is not authenticated or not available');
        }

        return () => {
            console.log('AccountPage component unmounted');
            isMounted = false;
        };

    }, [isAuthenticated, user]);

    return (
        <div>
            <NavbarAuth />
            <h1>Account Information</h1>
            {userProfile ? (
                <>
                    <p><strong>Name:</strong> {userProfile.firstname} {userProfile.lastname}</p>
                    <p><strong>Email:</strong> {userProfile.email}</p>
                    <p><strong>User ID:</strong> {user.user_id}</p>
                </>
            ) : (
                <p>Loading user profile...</p>
            )}
        </div>
    );
};

export default AccountPage;