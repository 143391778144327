import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAuth } from "../Components/AuthContext.js";
import Navbar from '../Components/Navbar.js';
import './PageCSS/Login.css'; // Import the custom CSS

export default function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { login } = useAuth();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/login`, { email, password });

            const { token, userData } = response.data;
            const expirationTime = new Date().getTime() + 60 * 60 * 1000;

            login(token, expirationTime, userData);
            setMessage('Login successful');
            navigate('/dashboard'); // redirect to dashboard
        } catch (e) {
            console.error('Error:', e.response ? e.response.data : e.message);
            setMessage(e.response ? e.response.data.message : 'Server error');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className = "App">
            <header className = "siteHeader text-white text-center">
                <Navbar />
            </header>
            <div className="container mt-5">
                <div className="row justify-content-center">
                    <div className="col-md-6">
                        <div className="card">
                            <div className="card-body">
                                <h1 className="card-title text-center">Login</h1>
                                <form onSubmit={handleSubmit}>
                                    <div className="form-group">
                                        <label>Email</label>
                                        <input type='email' className="form-control" value={email} onChange={(e) => setEmail(e.target.value)} />
                                    </div>
                                    <div className="form-group">
                                        <label>Password</label>
                                        <input type='password' className="form-control" value={password} onChange={(e) => setPassword(e.target.value)} />
                                    </div>
                                    <button type="submit" className="btn btn-primary btn-block" disabled={loading}>
                                        {loading ? 'Logging in...' : 'Log In'}
                                    </button>
                                </form>
                                <a href='/register' className="d-block text-center mt-3">New User</a>
                                <a href = '/requestPasswordReset' className = "d-block text-center mt-3">Forgot Password?</a>
                                {message && <p className="text-center mt-3">{message}</p>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};