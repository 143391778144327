import "./ComponentStyling/ProjectPage.css";
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import NavbarAuth from './Navbar_Authenticated';
import NewArtifactDropdown from "./NewArtifactDropdown";
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

const ProjectPage = () => {
    const { projectId } = useParams(); // Get projectId from URL
    const [project, setProject] = useState(null);
    const [stakeholders, setStakeholders] = useState([]);
    const [raidlog, setRAID] = useState([]);
    const [deleteProjectName, setDeleteProjectName] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const fetchProject = async () => {
            console.log('Fetching project with id: ', projectId);
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/projects/id/${projectId}`);
                setProject(response.data);
            } catch (error) {
                console.error('Error fetching project:', error);
            }
        };

        const fetchStakeholders = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/projects/${projectId}/stakeholders`);
                setStakeholders(response.data);
            } catch (error) {
                console.error('Error fetching stakeholders:', error);
            }
        };

        const fetchRAID = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/projects/${projectId}/raidlog`);
                setRAID(response.data);
            } catch (error) {
                console.error('Error fetching RAID log:', error);
            }
        };

        fetchProject();
        fetchStakeholders();
        fetchRAID();
    }, [projectId]);

    const handleDelete = async () => {
        if (deleteProjectName !== project.project_name) {
            alert('Project name does not match. Please type the correct project name to delete');
            return;
        }

        try {
            const response = await axios.delete(`${process.env.REACT_APP_API_URL}/api/projects/${projectId}`, {
                data: { projectName: deleteProjectName }
            });
            console.log('Project deleted', response.data);
            navigate('/dashboard');
        } catch (error) {
            console.error('Error deleting project:', error.response ? error.response.data : error.message);
        }
    };

    if (!project) return <p>Loading...</p>;

    return (
        <div className="App">
            <NavbarAuth />
            <div className="container-fluid">
                <div className="row">
                    <nav className="col-md-3 col-lg-2 d-md-block bg-light sidebar">
                        <div className="position-sticky pt-3">
                            <ul className="nav flex-column">
                                <li className="nav-item">
                                    <Link className="nav-link active" to={`/projects/${projectId}`}>
                                        Project Overview
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to={`/projects/${projectId}/stakeholders`}>
                                        Stakeholder Register
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to={`/projects/${projectId}/raidlog`}>
                                        RAID Log
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </nav>
                    <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
                        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                            <h1 className="h2">{project ? project.project_name : 'Loading project name'}</h1>
                        </div>
                        <p>User ID: {project.user_id}</p>
                        {/* Add more project details as needed */}
                        
                        <NewArtifactDropdown />

                        <div className="card mt-4">
                            <div className="card-body">
                                <h2>Delete Project</h2>
                                <input
                                    type='text'
                                    className="form-control mb-3"
                                    placeholder='Type project name to delete'
                                    value={deleteProjectName}
                                    onChange={(e) => setDeleteProjectName(e.target.value)}
                                    required
                                />
                                <button className="btn btn-danger" onClick={handleDelete}>Delete Project</button>
                            </div>
                        </div>

                        <h2 className="mt-5">Artifacts</h2>
                        <div className="list-group">
                            {stakeholders.length > 0 && (
                                <Link to={`/projects/${projectId}/stakeholders`} className="list-group-item list-group-item-action">
                                    Stakeholder Register
                                </Link>
                            )}
                            {raidlog.length > 0 && (
                                <Link to={`/projects/${projectId}/raidlog`} className="list-group-item list-group-item-action">
                                    RAID Log
                                </Link>
                            )}
                        </div>
                    </main>
                </div>
            </div>
        </div>
    );
};

export default ProjectPage;
