import "./PageCSS/Home.css";
import Navbar from '../Components/Navbar.js';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

export default function Home() {
    const navigate = useNavigate();
    
    const handleGetStartedClick = () => {
        navigate(`/register`);
    };
    
    return (
        <div className="App">
            <header className="siteHeader text-white text-center">
                <Navbar />
            </header>
            <main className="container-fluid">
                <div className="row justify-content-center">
                    <div className="col-lg-12">
                        <div className="welcomeCard">
                            <div className="welcomeCard-content text-center">
                                <h1>Every Project Manager's Dream</h1>
                                <h6>Many Projects, One Source</h6>
                                <button className="btn btn-primary getStarted" type="button" onClick={handleGetStartedClick}>Get Started</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row productFeatures text-center">
                    <div className="col-lg-4">
                        <p>Product Feature 1</p>
                    </div>
                    <div className="col-lg-4">
                        <p>Product Feature 2</p>
                    </div>
                    <div className="col-lg-4">
                        <p>Product Feature 3</p>
                    </div>
                </div>
                <div className="row aboutUs">
                    <div className="col-lg-4">
                        <h1>About Us</h1>
                    </div>
                    <div className="col-lg-8">
                        <p>[Insert About Us]</p>
                    </div>
                </div>
            </main>
        </div>
    );
};